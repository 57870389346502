import { Form, InputGroup, Col } from "react-bootstrap";
import ContactStyle from "./Contact.module.css";

const contactTitle = ContactStyle.contactTitle;
const formField = ContactStyle.formField;
const infoText = ContactStyle.infoText;

const MobileContactData = [
  {
    id: 1,
    field: (
      <>
        <h2 className={contactTitle}>Contact</h2>
      </>
    ),
  },
  {
    id: 2,
    field: (
      <>
        <p className={infoText}>
          <b>
            Thank you for your interest in Pocket Of Hope LLC baptismal towels,
            please fill out the form below and we will be sure to get back to
            you soon.
          </b>{" "}
          <br />
          <br />
          Pocket Of Hope LLC | EM: info@pocketofhope.net <br />
        </p>
      </>
    ),
  },
  {
    id: 3,
    field: (
      <>
        <Form.Group as={Col}>
          <InputGroup hasValidation>
            <Form.Control
              id="name"
              className={formField}
              size="sm"
              name="name"
              type="text"
              required
              minLength={3}
              maxLength={20}
              placeholder="NAME"
            />
            <Form.Control.Feedback
              style={{ marginTop: "-.9rem" }}
              type="invalid"
            >
              Full name required
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </>
    ),
  },
  {
    id: 4,
    field: (
      <>
        <Form.Group as={Col} sm={12}>
          <InputGroup hasValidation>
            <Form.Control
              className={formField}
              size="sm"
              name="phone"
              type="tel"
              pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
              required
              placeholder="PHONE"
            />
            <Form.Control.Feedback
              style={{ marginTop: "-.9rem" }}
              type="invalid"
            >
              Please enter a valid phone number format
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </>
    ),
  },
  {
    id: 5,
    field: (
      <>
        <Form.Control
          className={formField}
          size="sm"
          name="email"
          type="email"
          rows={3}
          required
          placeholder="E-MAIL"
        />
      </>
    ),
  },
  {
    id: 6,
    field: (
      <Form.Control
        className={formField}
        size="sm"
        as="textarea"
        name="message"
        type="text"
        rows={3}
        placeholder="MESSAGE"
      />
    ),
  },
];

export default MobileContactData;
