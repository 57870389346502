import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Screen from "./Components/Screen/Screen";
import ThankYou from "./Components/Contact/Thankyou";

const App = () => {
  return (
    <>
      <BrowserRouter basename="/">
        <Routes>
          <Route exact path="/sale" element={<Screen />} />
          <Route exact path="sale/thankyou/:name" element={<ThankYou />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
