import AboutStyle from "../AboutPoh/AboutPoh.module.css";
import aboutImage from "../../Assets/about-profile-2.png";
import { Button, Image } from "react-bootstrap";
import SwiperMode from "./SwiperMode";
import { useMediaQuery } from "@mui/material";

const HeaderMap = () => {
  const matchMobile = useMediaQuery("(max-width: 950px)");
  const headerWrapper = AboutStyle.headerWrapper;
  const imageWrapper = AboutStyle.imageWrapper;
  const missionWrapper = AboutStyle.missionWrapper;
  const name = AboutStyle.name;
  const about = AboutStyle.about;
  const title = AboutStyle.title;
  const swiperDiv = AboutStyle.swiperDiv;
  const hrStyle = AboutStyle.hrStyle;

  // const btn = AboutStyle.btn;
  const aboutImg = AboutStyle.aboutImg;
  // const btnWrapper = AboutStyle.btnWrapper;
  const aboutSection = AboutStyle.aboutSection;
  const imgContentWrapper = AboutStyle.imgContentWrapper;
  const asideStyle = AboutStyle.asideStyle;

  const content1 = (
    <>
      <div className={missionWrapper}>
        <p>
          Pocket of Hope LLC was born in 2013 out of my passion for helping and
          encouraging people to have Hope when challenged with difficult
          decisions and times. My line of anointed towels pulled at my heart
          strings to inspire people who are going through trials and
          tribulations whether it be illnesses, stress, anxiety, divorce, loss
          of a love one, emotional support or plainly said, comforting from the
          “comforting healer”. My prayer is that when you Wrap yourself in this
          anointed towel, it will give you Hope for encouragement to fight on,
          Hope for peace of mind, Hope for salvation and the Hope to have Faith
          the size of a mustard seed.
        </p>
        <p>
          "Hebrews 11: (1) Now Faith is the substance of things Hoped for, the
          evidence of things not seen, (2) for by it the elders obtained a good
          report.
        </p>
        <em>--JUST BELIEVE</em>
      </div>
    </>
  );
  return (
    <>
      <div id="about" className={headerWrapper}>
        <div>
          <div className={imgContentWrapper}>
            <div className={imageWrapper}>
              <Image src={aboutImage} className={aboutImg} />
              <h1 className={name}>Denise Kennedy</h1>
              <br />
            </div>
            <aside className={asideStyle}>
              <section className={aboutSection}>
                <h1 className={about}>
                  Mission <br />
                  Statement
                </h1>
                <h2 className={title}>
                  <p>{content1}</p>
                  {/* <p>{content1}</p> */}
                </h2>
                {/* <div className={btnWrapper}>
                  <Button href="#Announcements" className={btn}>
                    READ MORE
                  </Button>
                  <br />
                </div> */}
              </section>
              <hr className={hrStyle} />

              {matchMobile && (
                <div className={swiperDiv}>
                  <SwiperMode />
                </div>
              )}
            </aside>
          </div>
        </div>
        {!matchMobile && (
          <div id="test" className={swiperDiv}>
            <SwiperMode />
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderMap;
