import React from "react";
import Footer from "../Footer/Footer";
import ContactMap from "../Contact/ContactMap";
import Store from "../Store/Store";
import HeaderMap from "../Header/HeaderMap";
import AboutPohMap from "../AboutPoh/AboutPohMap";
import Nav from "../Nav/Nav";
import style from "../Screen/ScreenStyle.module.css";
import CommingSoon from "../CommingSoon/CommingSoon";

const screenDiv = style.screenDiv;

const Screen = () => {
  return (
    <>
      <div
        style={{
          maxWidth: "1600px",
          margin: "0 auto",
          // position: "absolute",

          backgroundColor: "transparent",
        }}
      >
        {/* <CommingSoon />*/}
        <Nav />
        <HeaderMap />

        <AboutPohMap />
        <Store />
        <ContactMap />
        <Footer />
      </div>

      {/* </div> */}
    </>
  );
};

export default Screen;
