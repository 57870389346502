import StoreStyle from "../Store/Store.module.css";
import storeLogo from "../../Assets/full-logo-bigger-1.png";
import storeLogoWhite from "../../Assets/full-logo-bigger-white.png";
import ProductsMap from "./ProductMap";
import ProductData from "./ProductData";

import {
  Accordion,
  AccordionContext,
  Button,
  Image,
  useAccordionButton,
} from "react-bootstrap";

import { useMediaQuery } from "@mui/material";
import { useContext } from "react";

const HeaderMap = () => {
  const matchDesktop = useMediaQuery("(min-width: 726px)");
  const btnReadMore = StoreStyle.btnReadMore;
  const headerWrapper = StoreStyle.headerWrapper;
  const storeImgStyle = StoreStyle.storeImgStyle;
  const storeTitle = StoreStyle.storeTitle;
  const aboutSection = StoreStyle.aboutSection;
  const imgContentWrapper = StoreStyle.imgContentWrapper;
  const asideStyle = StoreStyle.asideStyle;
  const productsContainer = StoreStyle.productsContainer;

  const products = ProductData.map((product) => (
    <ProductsMap
      key={product.id}
      product={product.product}
      prodTitle={product.prodTitle}
      content={product.content}
      link={product.link}
    />
  ));

  // Function to toggle the read more button
  const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
      <div style={{ textAlign: "end" }}>
        <Button
          type="button"
          // style={{
          //   // display: isCurrentEventKey ? "none" : "inline",
          //   margin: "-5px 14px 10px 0px",
          // }}
          onClick={decoratedOnClick}
          className={btnReadMore}
        >
          {/* {children} */}
          {isCurrentEventKey ? "CLOSE" : <>VIEW COMING SOON NEW ARRIVALS</>}
        </Button>
      </div>
    );
  };
  //-----------------------------------------------------------------------------------

  return (
    <>
      <div id="store" className={headerWrapper}>
        <div>
          <div className={imgContentWrapper}>
            {/* <Image src={aboutImage} className={aboutImg} /> */}

            <aside className={asideStyle}>
              <section className={aboutSection}>
                {!matchDesktop && (
                  <Image src={storeLogo} className={storeImgStyle} />
                )}
                {matchDesktop && (
                  <Image src={storeLogoWhite} className={storeImgStyle} />
                )}
                <h2 className={storeTitle}>STORE</h2>

                <div className={productsContainer}>{products}</div>

                <Accordion defaultActiveKey="0" alwaysOpen={false}>
                  <>{/* button content goes here*/}</>

                  {/* shoes the extended text when button is clicked  ---------------*/}
                  <Accordion.Collapse eventKey="1">
                    <>
                      <ul style={{ color: "white" }}>
                        <p>New Arrivals Coming Soon to Our Store:</p>

                        <li>Anointing Oil</li>
                        <li>Lap Clothes/Handkerchiefs</li>
                        <li>Usher Uniforms</li>
                        <li>Return of the “Hooded Jacket”</li>
                        <li>Kitchen Apparel (towels, aprons, etc.)</li>
                        {/* <li>Medical Uniforms</li> */}
                      </ul>
                    </>
                  </Accordion.Collapse>
                  <ContextAwareToggle eventKey="1"></ContextAwareToggle>
                  {/* ------------------------------------------------------------- */}
                </Accordion>
              </section>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderMap;
