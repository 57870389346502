import HeaderStyle from "../Header/Header.module.css";
import logo from "../../Assets/small-logo.png";
// import logoBigger from "../../Assets/small-logo-bigger.png";
import fullLogoMObile from "../../Assets/full-logo-mobile.png";
import fullLogoBigger from "../../Assets/full-logo-xl-2.png";
import vaseAndFlowers from "../../Assets/vase-flowers-mobile.png";
import vaseAndFlowers500 from "../../Assets/vase-flowers-555.png";
import { Button, Image } from "react-bootstrap";
import { useMediaQuery } from "@mui/material";

const HeaderMap = () => {
  const matchMobile = useMediaQuery("(max-width: 500px)");
  const headerWrapper = HeaderStyle.headerWrapper;
  const logoImg = HeaderStyle.logoImg;
  const fullLogoStyle = HeaderStyle.fullLogoStyle;
  const vaseFlowers = HeaderStyle.vaseFlowers;
  const name = HeaderStyle.name;
  const title = HeaderStyle.title;
  const btn = HeaderStyle.btn;

  const titleWrapper = HeaderStyle.titleWrapper;

  const btnWrapper = HeaderStyle.btnWrapper;

  return (
    <>
      <div className={headerWrapper}>
        {matchMobile ? (
          <Image src={logo} className={logoImg} />
        ) : (
          <Image src={logo} className={logoImg} />
        )}

        <div className={titleWrapper}>
          <div>
            {matchMobile ? (
              <Image
                src={fullLogoMObile}
                alt="logo"
                className={fullLogoStyle}
              />
            ) : (
              <Image src={fullLogoBigger} className={fullLogoStyle} />
            )}

            {/* <h1 className={name}>Anointed Baptismal Towels & Oil</h1> */}
            <h1 className={name}>Anointed Baptismal Towels</h1>
            <h2 className={title}>
              <p>
                Pocket of Hope LLC is a company that provides high quality
                baptismal towels as well as towels for your everyday use. We
                believe in moving in a spirit of excellence, providing quality,
                anointed products that will enhance your life changing
                experience when you believe.
              </p>
              {/* <p style={{ marginTop: "-7px" }}>
                We are committed to providing you with the best products and
                service possible, and we look forward to helping you with all of
                your baptismal needs.
              </p> */}
            </h2>
            <div className={btnWrapper}>
              <Button href="#store" className={btn}>
                BUY NOW
              </Button>
              <br />
            </div>
          </div>
        </div>
        {matchMobile ? (
          <Image src={vaseAndFlowers} className={vaseFlowers} />
        ) : (
          <Image src={vaseAndFlowers500} className={vaseFlowers} />
        )}
      </div>
    </>
  );
};

export default HeaderMap;
