import { Swiper, SwiperSlide } from "swiper/react";
import React, { useRef, useState } from "react";
import AboutStyle from "../AboutPoh/AboutPoh.module.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../style.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const testimoniesTitle = AboutStyle.testimoniesTitle;

const SwiperMode = () => {
  return (
    <>
      <h2 className={testimoniesTitle}>Testimonies</h2>
      <Swiper
        id="testimonies"
        spaceBetween={100}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        navigation={true}
        className="swiper"
      >
        <SwiperSlide>
          <div className="circleBase circle-img4-a"></div>

          <aside className="aside-icon">
            I love seeing the Baptismal towels wrapped around a candidate for
            baptism, so fulfilling.
            <h2>Minister Norman</h2>
            {/* <div className="circleBase circle-img4"></div> */}
          </aside>
        </SwiperSlide>
        <SwiperSlide>
          <div className="circleBase circle-img5-a"></div>
          <aside className="aside-icon">
            I just love this line of towels and apparel. It has always been my
            favorite and gives me Hope to carry on.
            <h2>Bernice J.</h2>
            {/* <div className="circleBase circle-img5"></div> */}
          </aside>
        </SwiperSlide>
        <SwiperSlide>
          <div className="circleBase circle-img6-a"></div>
          <aside className="aside-icon">
            I am still wearing and getting compliments on my comfortable Pocket
            of Hope hooded jacket. I love it!
            <h2>Marie K.</h2>
            {/* <div className="circleBase circle-img6"></div> */}
          </aside>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
export default SwiperMode;
