import React from "react";
import { Button } from "react-bootstrap";
import ContactStyle from "../Contact.module.css";

const SubmitButton = ({ ffIsValid, validated }) => {
  const btn = ContactStyle.btn;
  const btnDiv = ContactStyle.btnDiv;

  return (
    <div className={btnDiv}>
      <Button size="sm" variant="light" type="submit" value="send" className={btn}>
        SUBMIT
      </Button>
    </div>
  );
};
export default SubmitButton;