import React from "react";

const ContactProps = (props) => {
    return (
        <>
            <>{props.field}</>
        </>        
    )
};

export default ContactProps;