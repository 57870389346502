import { useParams } from "react-router-dom";
import { Button, CardText, Image } from "react-bootstrap";
import { Container, Card } from "react-bootstrap";
import logo from "../../Assets/full-logo-bigger-white.png";
// import "./style.css";

const ThankYou = () => {
  const { name } = useParams();
  console.log(name);

  return (
    <>
      <Container style={{ padding: "1rem", textAlign: "center" }}>
        <Card
          style={{
            padding: "1rem",
            backgroundColor: "#a21f24",
            color: "white",
          }}
        >
          <Image src={logo} />
          <h2>
            <b>Thank you {name}</b>,
          </h2>
          <CardText>
            We appreciate your interest in Pocket Of Hope baptismal towels. Your
            inquiry has been successfully sent. We will be in contact soon!
          </CardText>
          <div>
            <Button variant={"dark"} href="/tmp">
              Back to Home
            </Button>
          </div>
        </Card>
      </Container>
    </>
  );
};
export default ThankYou;
