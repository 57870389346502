import { Form, InputGroup, Col, Row } from "react-bootstrap";
import ContactStyle from "./Contact.module.css";

const contactTitle = ContactStyle.contactTitle;
const labelForm = ContactStyle.labelForm;
const name = ContactStyle.name;
const phone = ContactStyle.phone;
const email = ContactStyle.email;
const message = ContactStyle.message;
const infoText = ContactStyle.infoText;
const infoTextB = ContactStyle.infoTextB;

const DesktopContactData = [
  {
    id: 1,
    field: (
      <>
        <h3 className={contactTitle}>Contact</h3>
      </>
    ),
  },
  {
    id: 2,
    field: (
      <>
        <p className={infoText}>
          Thank you for your interest in Pocket LLC Of Hope baptismal towels.
          Please fill out the form below and we will be sure to get back to you
          soon.
        </p>
      </>
    ),
  },
  {
    id: 3,
    field: (
      <>
        <p className={infoTextB}>
          Pocket Of Hope LLC | EM: info@pocketofhope.net
        </p>
      </>
    ),
  },
  {
    id: 4,
    field: (
      <div>
        <Form.Group as={Row}>
          <Col>
            <InputGroup hasValidation>
              <Form.Control
                id="name"
                className={name}
                size="sm"
                name="name"
                type="text"
                required
                minLength={3}
                maxLength={20}
                placeholder="Name"
              />
              <Form.Control.Feedback
                style={{ marginTop: "-.9rem" }}
                type="invalid"
              >
                Full name required
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Form.Group>
      </div>
    ),
  },
  {
    id: 5,
    field: (
      <div>
        <Form.Group as={Row}>
          <Col>
            <InputGroup hasValidation>
              <Form.Control
                className={phone}
                size="sm"
                name="phone"
                type="tel"
                pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                required
                placeholder="Phone"
              />
              <Form.Control.Feedback
                style={{ marginTop: "-.9rem" }}
                type="invalid"
              >
                Please enter a valid phone number format
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Form.Group>
      </div>
    ),
  },
  {
    id: 6,
    field: (
      <div>
        <Form.Group as={Row}>
          <Col>
            <Form.Control
              className={email}
              size="sm"
              name="email"
              type="email"
              rows={3}
              required
              placeholder="Email"
            />
          </Col>
        </Form.Group>
      </div>
    ),
  },
  {
    id: 7,
    field: (
      <div>
        <Form.Group as={Row}>
          <Col>
            <Form.Control
              className={message}
              size="sm"
              as="textarea"
              name="message"
              type="text"
              rows={3}
              placeholder="Message"
            />
          </Col>
        </Form.Group>
      </div>
    ),
  },
];

export default DesktopContactData;
