import StoreStyle from "../Store/Store.module.css";
import { Button, Image } from "react-bootstrap";

const ProductMap = (props) => {
  const title = StoreStyle.title;
  const btn = StoreStyle.btn;
  const productImg = StoreStyle.productImg;
  const productTitle = StoreStyle.productTitle;
  const btnWrapper = StoreStyle.btnWrapper;
  const innerContainer = StoreStyle.innerContainer;

  return (
    <>
      <div className={innerContainer}>
        {/* <Image src={productOne} className={productImg} /> */}
        <Image src={props.product} className={productImg} />

        <div style={{ textAlign: "center" }}>
          <p className={productTitle}>{props.prodTitle}</p>{" "}
        </div>

        <h2 className={title}>
          <p>{props.content}</p>
        </h2>

        <div className={btnWrapper}>
          <Button
            // href="https://pocket-of-hope.printify.me/product/14091690/hand-towel-long-logo"
            href={props.link}
            className={btn}
          >
            VIEW PRODUCT
          </Button>
        </div>
      </div>
    </>
  );
};

export default ProductMap;
