import React, { useState } from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import NavStyle from "./Nav.module.css";
import "../../style.css";

const Navigation = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navBar = NavStyle.navBar;
  const containerNav = NavStyle.containerNav;
  const expandedNavBar = isExpanded ? NavStyle.expandedNavBar : "";
  const mainContentClass = isExpanded ? NavStyle.mainContentExpanded : "";

  return (
    <>
      <Navbar
        className={`${navBar} ${expandedNavBar} navbar`}
        expand="lg"
        expanded={isExpanded}
        onToggle={() => setIsExpanded(!isExpanded)}
      >
        <Container className={containerNav}>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/" style={{ color: "white" }}>
                Home
              </Nav.Link>
              <Nav.Link href="#about" style={{ color: "white" }}>
                About
              </Nav.Link>
              <Nav.Link href="#store" style={{ color: "white" }}>
                Store
              </Nav.Link>
              <Nav.Link href="#contact" style={{ color: "white" }}>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className={`${NavStyle.mainContent} ${mainContentClass}`}>
        {/* Main content of the website */}
      </div>
    </>
  );
};
export default Navigation;
