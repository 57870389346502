import productOne from "../../Assets/prod-1b.jpg";
import productTwo from "../../Assets/prod-2b.jpg";
import productThree from "../../Assets/prod-3b.jpg";
import productFour from "../../Assets/prod-4b.jpg";

const ProductData = [
  // {
  //   id: 1,
  //   product: productOne,
  //   prodTitle: "Cotton Tea Towel",
  //   content:
  //     "This (insert size) personalized hand towel is made from 100% controlled, organically cultivated cotton for incredible softness.",
  //   link: "https://pocket-of-hope.printify.me/product/15320086/cotton-tea-towel",
  // },
  // {
  //   id: 2,
  //   product: productTwo,
  //   prodTitle: "Tea Towel Logo Pocket Design",
  //   content:
  //     "This (insert size) Tea towel made with 100% cotton and is super soft, this towel is perfect for baptismal use. Please note allow time to ship, shipping is from Europe.",
  //   link: "https://pocket-of-hope.printify.me/product/15319830/tea-towel-pattern-design",
  // },
  {
    id: 1,
    product: productOne,
    prodTitle: "Baptismal Hand Towel",
    content: `This 28" × 16" The front face is made with a 100% polyester blend, while the back is 100% soft, absorbent cotton.`,
    link: "https://pocket-of-hope.printify.me/product/14198413/baptismal-hand-towel-corner-logo",
  },
  {
    id: 2,
    product: productTwo,
    prodTitle: "Baptismal Face Towel",
    content: `This 13" × 13"" The front face is made with a 100% polyester blend, while the back is 100% soft, absorbent cotton.`,
    link: "https://pocket-of-hope.printify.me/product/14198337/baptismal-face-towel-corner-logo",
  },
  {
    id: 3,
    product: productThree,
    prodTitle: <>Baptismal Face Towel</>,
    content: `This 13" × 13"" The front face is made with a 100% polyester blend, while the back is 100% soft, absorbent cotton.`,
    link: "https://pocket-of-hope.printify.me/product/14091594/baptismal-face-towel-pocket-logo",
  },
  {
    id: 4,
    product: productFour,
    prodTitle: "Large Baptismal Towel",
    content: `Choose from two sizes: 30" X 60", or 36" × 72. Fine fibres of combed cotton create a soft and durable towel. This extremely strong and durable synthetic fabric retains its shape and dries quickly.`,
    link: "https://pocket-of-hope.printify.me/product/13975664/large-baptismal-towel-org-logo",
  },
  // {
  //   id: 4,
  //   product: productFour,
  //   prodTitle: "Tea Towel Logo Word Design",
  //   content:
  //     "This (insert size) Tea towel made with 100% cotton and is super soft, this towel is perfect for baptismal use. Please note allow time to ship, shipping is from Europe.",
  //   link: "https://pocket-of-hope.printify.me/product/15319548/tea-towel",
  // },
];

export default ProductData;
